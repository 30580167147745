import React, { useState, useEffect, useRef } from 'react';
import pako from 'pako';
import { Accordion, AccordionTitleProps } from 'semantic-ui-react';
import ContactDetails from './ContactDetails';
import CallLog from './CallLog';
import QueueMetrics from './QueueMetrics';
import PeerStatus from './PeerStatus';
import { IAgent, ICall, IQueue, IContact, IUser } from '../interfaces';
import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import config from '../config';
import { bool } from 'aws-sdk/clients/signer';

declare const window: any;

const Metrics: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number>(3);
  const [contact, setContact] = useState<IContact | {}>({});
  const [callLog, setCallLog] = useState<ICall[]>([]);
  const [queues, setQueues] = useState<IQueue[]>([]);
  const [agents, setAgents] = useState<IAgent[]>([]);
  const [token, setToken] = useState<string>('');
  const [toggle, setToggle] = useState<bool>(false);
  const [user, setUser] = useState<IUser>({
    name: '',
    username: '',
    routingProfile: '',
    queues: [],
  });
  const [retries, setRetries] = useState<number>(0);

  const ws = useRef<WebSocket | any>();
  const uiBlockerID: string = 'block-clear-contact';

  useEffect(() => {
    refreshTokens();
  }, []);

  useEffect(() => {
    if (!user.username || !token) return;

    if (retries > 30) {
      refreshUser();
      return;
    }

    startWSS(user.username, token);

    return () => {
      ws.current.close();
      console.log('Closed connection');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.username, retries]);


  useEffect(() => {
    if (ws.current) {
      ws.current.onmessage = (event: MessageEvent) => {
        const response = JSON.parse(event.data);
        // console.log(`Websocket response data: ${JSON.stringify(response)}`);

        if (response.data && response.data.type === "Buffer") {
          const compressedData = new Uint8Array(response.data.data);
          const decompressedData = pako.inflate(compressedData, { to: 'string' });
          let jsonData = JSON.parse(decompressedData);

          handleResponse(jsonData);
        } else {
          handleResponse(response);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callLog, agents, queues, user, retries]);


  useEffect(() => {
    subscribeConnectEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshUser = () => {
    console.log('Refreshing page for user');
    window.location.reload();
  };

  const refreshTokens = () => {
    console.log('Refreshing user access tokens');
    Auth.currentSession().then((session: CognitoUserSession) => {
      setToken(session.getAccessToken().getJwtToken());
    });
  };

  const startWSS = (username: string, token: string) => {
    const subscribe = { action: 'init' };
    const params = {
      AgentUsername: username,
      token,
    };

    ws.current = new WebSocket(
      [config.apiGateway.WSS_URL, new URLSearchParams(params).toString()].join(
        '?'
      )
    );

    ws.current.onopen = () => {
      ws.current.send(JSON.stringify(subscribe));
      console.log('Subscribing to WSS');
    };

    ws.current.onclose = () => {
      console.log('WSS has lost connection.');
      ws.current.close();

      refreshTokens();
      setTimeout(() => setRetries(retries + 1), 20000);
    };

    ws.current.onerror = () => {
      console.log('There was a websocket error');
    };

    window.WBS = ws.current;
  };

  const handleResponse = (response: any) => {

    // On initial page load, the response is recieved from dynamo table
    switch (response.Table) {
      case 'QueueMetrics':
        // console.log(`logging: QueueMetrics ${JSON.stringify(response)}`);
        handleQueues(response);
        break;
      case 'AgentMetrics':
        // console.log(`logging: AgentMetrics response.Table ${JSON.stringify(response)}`);
        handleAgents(response);
        break;
      case 'AgentCallLog':
        // console.log(`logging: AgentCallLog ${JSON.stringify(response)}`);
        handleCallLog(response);
        break;
      default:
        console.log('Unrecognized message from websocket', response);

        // After page load, Webscoket sends responses triggered by agent status change via onSubscribe function 
        // On page load response init value is true, after that it's false and the resopnse structure changes thus a new switch/case
        if (response.data.records) {
          switch (response.data.records[0].Table) {
            case 'AgentMetrics':
              // console.log(`logging: AgentMetrics response.data.records ${JSON.stringify(response)}`)

              const updateAgent = response.data.records[0];
              const oldAgentState = agents;
              const updatedAgentState: [] = [];
              let newAgent = true;

              oldAgentState.forEach((agent) => {

                if (agent.AgentUsername === response.data.records[0].AgentUsername) {
                  agent.Status = updateAgent.Status;
                  newAgent = false;
                };

                if (agent.Status !== 'Offline') {
                  // @ts-ignore // We get a state change error in the console but it's not code breaking
                  // Push the agents and updated agent into a new array unless the new status is offline then we remove
                  updatedAgentState.push(agent)
                }
              })
              // @ts-ignore // We get a state change error in the console but it's not code breaking
              // If newAgent is still assigned to true then this is the first sign on of the agent so we need to add whole agent obj to state
              if (newAgent === true) updatedAgentState.push(updateAgent)

              // Set agents with updated status if not offline
              setAgents(noOfflineAgents(updatedAgentState.filter(isRelevantAgent)));
              break;
            case 'QueueMetrics':
              // QueueMetrics Case for false initialization
              // console.log(`z QueueMetrics response: ${JSON.stringify(response.data.records[0])}`)

              response.data.records.forEach((queueResponse: IQueue) => {
                // Invoke handleQueues but response is different then when init is true so adjust for that here
                handleQueues(queueResponse)
              })
              break;
            case 'AgentCallLog':
              response.data.records.forEach((callResponse: any) => {

                handleCallLog(callResponse)
              })
              break;
          }
        }
    }
  };


  const handleQueues = (response: MessageEvent['data']) => {

    if (response.init) {
      setQueues(response.Items.filter(isRelevantQueue));
      return;
    }

    if (isRelevantQueue(response)) {
      // replace queue in current array, if present
      const index = queues.findIndex(
        (queue: IQueue) => queue.Queue === response.Queue
      );

      if (index === -1) {
        setQueues([response, ...queues]);
        return;
      }

      setQueues(replaceAndReturn(queues, index, response));
    }
  };

  const handleAgents = (response: MessageEvent['data']) => {

    if (response.init) {
      !user.department
        ? findMyDepartmentFirst(response.Items)
        : setAgents(noOfflineAgents(response.Items.filter(isRelevantAgent)));
      return;
    }

    if (isRelevantAgent(response)) {
      let newAgentsState: IAgent[];
      // replace agent in current array, if present
      const index = agents.findIndex(
        (agent: IAgent) => agent.AgentUsername === response.AgentUsername
      );

      if (index === -1) {
        newAgentsState = [response, ...agents];
      } else {
        newAgentsState = replaceAndReturn(agents, index, response);
      }

      setAgents(noOfflineAgents(newAgentsState));
    }
  };

  const replaceAndReturn = (
    array: any[],
    index: number,
    newValue: any
  ): any[] => {
    const newArray = array.slice();
    newArray.splice(index, 1, newValue);
    return newArray;
  };

  const noOfflineAgents = (agents: IAgent[]): IAgent[] => {
    return agents.filter((agent: IAgent) => isOnlineAgent(agent));
  };

  const handleCallLog = (response: MessageEvent['data']) => {
    console.log('handleCallLog func: ', response)

    if (response.init) {
      setCallLog(dateSort(response.Items));
      return;
    }

    if (Array.isArray(response)) {
      console.log('response type array');
      response.forEach((callLogObject) => {
        if (callLogObject.AgentUsername === user.username) setCallLog([callLogObject, ...callLog]);
      })
    } else if (typeof response === 'object' && response !== null) {
      console.log('response type object');
      if (response.AgentUsername === user.username) setCallLog([response, ...callLog]);
    }
  };

  const dateSort = (calls: ICall[]): ICall[] => {
    return calls.slice().sort((callA: ICall, callB: ICall): number => {
      return new Date(callA.ConnectedTimestamp) <
        new Date(callB.ConnectedTimestamp)
        ? 1
        : -1;
    });
  };

  const isRelevantQueue = (queue: IQueue) => {
    return user.queues.includes(queue.Queue);
  };

  const isRelevantAgent = (agent: IAgent) => {
    return (
      user.routingProfile === agent.RoutingProfile ||
      user.department === getAgentDepartment(agent)
    );
  };

  const isOnlineAgent = (agent: IAgent) => {
    return agent.Status !== 'Offline';
  };

  const findMyDepartmentFirst = (agents: IAgent[]) => {

    let myDepartment: string;
    // loop once through agents to find my agent profile and department
    agents.forEach((agent: IAgent) => {
      if (agent.AgentUsername === user.username) {
        // once user agent data found, set user's department and filter relevant agents
        myDepartment = getAgentDepartment(agent);
        setUser({ ...user, department: myDepartment });

        setAgents(
          noOfflineAgents(
            agents.filter((agent: IAgent) => {
              return (
                user.routingProfile === agent.RoutingProfile ||
                myDepartment === getAgentDepartment(agent)
              );
            })
          )
        );
        return; // return early
      }
    });
  };

  const getAgentDepartment = (agent: IAgent): string => {
    return [agent.HierarchyLevel1, agent.HierarchyLevel2].join('/');
  };

  const subscribeConnectEvents = () => {
    if (window.connect.core.initialized) {
      subscribeContactEvents();
      subscribeAgentEvents();
    } else {
      console.log('Connect not yet initialized, waiting to subscribe events');
      setTimeout(subscribeConnectEvents, 1000);
    }
  };

  const subscribeContactEvents = () => {
    console.log('Subscribing to Connect Contact Events');
    window.connect.contact((contact: any) => {
      contact.onConnecting((contact: any) => {
        console.log('Connecting to contact:', contact.contactId);

        const contactAttributes = contact.getAttributes();
        Object.keys(contactAttributes).forEach((key) => {
          contactAttributes[key] = contactAttributes[key].value;
        });
        contactAttributes.Phone = contact
          .getActiveInitialConnection()
          .getEndpoint().phoneNumber;
        console.log(JSON.stringify(contactAttributes));

        setContact(contactAttributes);
      });

      contact.onEnded(() => {
        console.log('Call ended', contact.contactId);
        setContact({});
      });
    });
  };

  const subscribeAgentEvents = () => {
    console.log('Subscribing to Connect Agent Events');
    window.connect.agent((agent: any) => {
      const agentConfig = agent.getConfiguration();
      const userAgentDetails: IUser = {
        name: agentConfig.name,
        username: agentConfig.username,
        routingProfile: agentConfig.routingProfile.name,
        queues: agentConfig.routingProfile.queues.map(
          (queue: any) => queue.name
        ),
      };

      setUser(userAgentDetails);

      agent.onStateChange((agent: any) => {
        const agentConfig = agent.getConfiguration();
        const userAgentDetails: IUser = {
          name: agentConfig.name,
          username: agentConfig.username,
          routingProfile: agentConfig.routingProfile.name,
          queues: agentConfig.routingProfile.queues.map(
            (queue: any) => queue.name
          ),
        };

        setToggle((toggle) => !toggle)

        // @ts-ignore
        setAgents({ ...agents, userAgentDetails })
      })

      agent.onAfterCallWork((agent: any) => {
        window.document.getElementById(uiBlockerID).style = undefined;
        console.log(
          'On after call work, blocking "clear contact" for 3 seconds'
        );
        setTimeout(() => {
          window.document.getElementById(uiBlockerID).style.display = 'none';
        }, 3000);
      });
    });
  };

  function handleClick(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    data: AccordionTitleProps
  ): void {
    const { index } = data;

    setActiveIndex(Number(index));
  }
  return (
    <Accordion styled>
      <Accordion.Title
        active={activeIndex === 0}
        index={0}
        onClick={handleClick}
      >
        Contact Details
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 0}>
        <ContactDetails contact={contact} />
      </Accordion.Content>

      <Accordion.Title
        active={activeIndex === 1}
        index={1}
        onClick={handleClick}
      >
        Call Log ({callLog.length})
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 1}>
        <CallLog callLog={callLog} />
      </Accordion.Content>

      <Accordion.Title
        active={activeIndex === 2}
        index={2}
        onClick={handleClick}
      >
        Queue Metrics ({queues.length})
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 2}>
        <QueueMetrics queues={queues} />
      </Accordion.Content>
      <Accordion.Title
        active={activeIndex === 3}
        index={3}
        onClick={handleClick}
      >
        Peer Status ({agents.length})
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 3}>
        <PeerStatus agents={agents} toggle={toggle} />
      </Accordion.Content>
    </Accordion>
  );
};

export default Metrics;
