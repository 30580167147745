// config file built according to format here https://serverless-stack.com/chapters/manage-environments-in-create-react-app.html

const redirect = (url: string) => {
  if (process.env.NODE_ENV === 'development') {
    return 'http://localhost:3000';
  }
  return url;
};

// frontend stage is determined by process.env.NODE_ENV and defaults to 'development' unless npm run build command is executed (then it's 'production')
// AWS backend variables listed here will be determined based on backend stage which will be controlled manually by environment variable REACT_APP_BACKEND_STAGE (defaults to 'dev')

const dev = {
  apiGateway: {
    REGION: 'us-west-2',
    WSS_URL: 'wss://3fuopisubg.execute-api.us-west-2.amazonaws.com/dev',
  },
  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_tU8zVvjQB',
    APP_CLIENT_ID: '1aqnafcg0fqg07p390vg1nkjrk',
    IDENTITY_POOL_ID: 'us-west-2:91c9026e-1d51-47d8-aaa8-4acb72ade28d',
    DOMAIN:
      'uarizona-connect-ccp-auth-domain-dev.auth.us-west-2.amazoncognito.com',
    SCOPE: ['email', 'openid', 'profile'],
    REDIRECT_SIGN_IN: redirect('https://d2esy5cpy6wdjo.cloudfront.net'),
    REDIRECT_SIGN_OUT: redirect('https://d2esy5cpy6wdjo.cloudfront.net'),
    RESPONSE_TYPE: 'code', // 'code' or 'token', note that REFRESH token will only be generated when the responseType is code
  },
  connect: {
    REGION: 'us-west-2',
    CCP_URL: 'https://dev-uarizona-contactcenter.my.connect.aws/ccp-v2/',
    SAML_LOGIN_URL:
      'https://shibboleth.arizona.edu/idp/profile/SAML2/Unsolicited/SSO?providerId=urn:amazon:webservices&target=https://us-west-2.console.aws.amazon.com/connect/federate/3a7ce935-1f3e-443c-9c40-84778e578679%3Fnew_domain%3Dtrue',
  },
};

const prod = {
  apiGateway: {
    REGION: 'us-west-2',
    WSS_URL: 'wss://2w8g6dfyi7.execute-api.us-west-2.amazonaws.com/prod',
  },
  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_5uceaDQpU',
    APP_CLIENT_ID: 'ijuc11d0pv85n0nck3ib64401',
    IDENTITY_POOL_ID: 'us-west-2:a95ee449-4cfe-4df0-90eb-d1c2e540912a',
    DOMAIN:
      'uarizona-connect-ccp-auth-domain-prod.auth.us-west-2.amazoncognito.com',
    SCOPE: ['email', 'openid', 'profile'], // oauth scopes
    REDIRECT_SIGN_IN: redirect('https://d2wr3lxb5ctyhr.cloudfront.net'), // URL of the app/cloudfront-distribution should go here
    REDIRECT_SIGN_OUT: redirect('https://d2wr3lxb5ctyhr.cloudfront.net'), // Same as above unless there is a specific logout route
    RESPONSE_TYPE: 'code', // 'code' or 'token', note that REFRESH token will only be generated when the responseType is code
  },
  connect: {
    REGION: 'us-west-2',
    CCP_URL: 'https://uarizona-contactcenter.my.connect.aws/ccp-v2/',
    SAML_LOGIN_URL:
      'https://shibboleth.arizona.edu/idp/profile/SAML2/Unsolicited/SSO?providerId=urn:amazon:webservices&target=https://us-west-2.console.aws.amazon.com/connect/federate/6d380d56-36ed-4d63-8342-e59e44ce841e%3Fnew_domain%3Dtrue',
  },
};

// Default to dev if not set
const config = process.env.REACT_APP_BACKEND_STAGE === 'prod' ? prod : dev;

export default {
  // Add common config values here
  // MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};
